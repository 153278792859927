import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";

const schema = Yup.object().shape({
  idInvestment: Yup.number().integer().min(0).required(),
  USDCPaymentTransaction: Yup.string().trim().required(),
});

const NewPayment = ({ isOpen, setIsOpen, newPayment }) => (
  <Formik
    validationSchema={schema}
    onSubmit={(evt) => {
      const {
        idInvestment,
        USDCPaymentTransaction,
      } = evt;

      newPayment(
        idInvestment.trim(),
        USDCPaymentTransaction.trim(),
      );

      setIsOpen(false);

      toast("Payment cadastrado!", { type: "success" });
    }}
    initialValues={{
      idInvestment: "",
      USDCPaymentTransaction: "",
    }}
  >
    {({ handleSubmit, handleChange, values, touched, errors }) => (
      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="newPayment" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Investment ID</Form.Label>
              <Form.Control
                type="text"
                name="idInvestment"
                value={values.idInvestment}
                onChange={handleChange}
                isValid={
                  touched.idInvestment && !errors.idInvestment
                }
                isInvalid={!!errors.idInvestment}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>USDC Payment Transaction</Form.Label>
              <Form.Control
                type="text"
                name="USDCPaymentTransaction"
                value={values.USDCPaymentTransaction}
                onChange={handleChange}
                isValid={
                  touched.USDCPaymentTransaction &&
                  !errors.USDCPaymentTransaction
                }
                isInvalid={!!errors.USDCPaymentTransaction}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <Button type="submit" variant="primary" form="newPayment">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default NewPayment;
