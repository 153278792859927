import dayjs from "dayjs";
import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { IMaskMixin } from "react-imask";
import { toast } from "react-toastify";
import * as Yup from "yup";

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
  <Form.Control {...props} ref={inputRef} />
));

const schema = Yup.object().shape({
  interestRate: Yup.string()
    .trim()
    .matches(/^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)%$/)
    .required(),
  investmentStartingDate: Yup.date().required(),
  investmentPaymentDate: Yup.date().required(),
  investmentAmount: Yup.number().integer().positive().required(),
  investmentDueAmount: Yup.number().integer().positive().required(),
  investorAddress: Yup.string().trim().required(),
  USDCInvestmentTransaction: Yup.string().trim().required(),
});

const NewInvestment = ({ isOpen, setIsOpen, newInvestment }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={(evt) => {
        const {
          interestRate,
          investmentStartingDate,
          investmentPaymentDate,
          investmentAmount,
          investmentDueAmount,
          investorAddress,
          USDCInvestmentTransaction,
        } = evt;

        console.log(
          parseInt(Number(interestRate.replace("%", "")) * 100),
          dayjs(investmentStartingDate.trim(), "YYYY-MM-DD").toDate().getTime() / 1000,
          dayjs(investmentPaymentDate.trim(), "YYYY-MM-DD").toDate().getTime() / 1000,
          investmentAmount.trim(),
          investmentDueAmount.trim(),
          investorAddress.trim(),
          USDCInvestmentTransaction.trim()
        );

        newInvestment(
          parseInt(Number(interestRate.replace("%", "")) * 100),
          dayjs(investmentStartingDate.trim(), "YYYY-MM-DD").toDate().getTime() / 1000,
          dayjs(investmentPaymentDate.trim(), "YYYY-MM-DD").toDate().getTime() / 1000,
          investmentAmount.trim(),
          investmentDueAmount.trim(),
          investorAddress.trim(),
          USDCInvestmentTransaction.trim()
        );

        setIsOpen(false);

        toast("Investimento cadastrado!", { type: "success" });
      }}
      initialValues={{
        interestRate: "",
        investmentStartingDate: null,
        investmentPaymentDate: null,
        investmentAmount: 0,
        investmentDueAmount: 0,
        investorAddress: "",
        USDCInvestmentTransaction: "",
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>New Investment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate id="newInvestment" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Interest Rate</Form.Label>
                <MaskedStyledInput
                  type="text"
                  name="interestRate"
                  value={values.interestRate}
                  onChange={handleChange}
                  isValid={touched.interestRate && !errors.interestRate}
                  isInvalid={!!errors.interestRate}
                  autoFocus
                  mask={[
                    {
                      mask: "",
                    },
                    {
                      mask: "num%",
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          scale: 2,
                          min: 0,
                          max: 100,
                          radix: ".",
                          mapToRadix: [","],
                        },
                      },
                    },
                  ]}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Investment Date</Form.Label>
                <Form.Control
                  type="date"
                  name="investmentStartingDate"
                  value={values.investmentStartingDate}
                  onChange={handleChange}
                  isValid={touched.investmentStartingDate && !errors.investmentStartingDate}
                  isInvalid={!!errors.investmentStartingDate}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Investment Payment Date</Form.Label>
                <Form.Control
                  type="date"
                  name="investmentPaymentDate"
                  value={values.investmentPaymentDate}
                  onChange={handleChange}
                  isValid={
                    touched.investmentPaymentDate &&
                    !errors.investmentPaymentDate
                  }
                  isInvalid={!!errors.investmentPaymentDate}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Investment Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="investmentAmount"
                  value={values.investmentAmount}
                  onChange={handleChange}
                  isValid={touched.investmentAmount && !errors.investmentAmount}
                  isInvalid={!!errors.investmentAmount}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Investment Due Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="investmentDueAmount"
                  value={values.investmentDueAmount}
                  onChange={handleChange}
                  isValid={
                    touched.investmentDueAmount && !errors.investmentDueAmount
                  }
                  isInvalid={!!errors.investmentDueAmount}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Investor Address</Form.Label>
                <Form.Control
                  type="text"
                  name="investorAddress"
                  value={values.investorAddress}
                  onChange={handleChange}
                  isValid={
                    touched.investorAddress &&
                    !errors.investorAddress
                  }
                  isInvalid={!!errors.investorAddress}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>USDC Investment Transaction</Form.Label>
                <Form.Control
                  type="text"
                  name="USDCInvestmentTransaction"
                  value={values.USDCInvestmentTransaction}
                  onChange={handleChange}
                  isValid={
                    touched.USDCInvestmentTransaction &&
                    !errors.USDCInvestmentTransaction
                  }
                  isInvalid={!!errors.USDCInvestmentTransaction}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button type="submit" variant="primary" form="newInvestment">
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default NewInvestment;
