import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CollateralList = ({ isOpen, setIsOpen, getCollateralList }) => {
  const [collateralList, setCollateralList] = useState("");

  async function getCollateralListText() {
    const collaterals = await getCollateralList();
    const text = collaterals
      .map((c) => "[" + c.ticker + "(" + parseInt(c.collateralAmount)/10**6 + ")]: " + parseInt(c.CPCAmount)/10**18 + "<br>")
      .join("");
    setCollateralList(text);
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      onShow={getCollateralListText}
    >
      <Modal.Header closeButton>
        <Modal.Title>Collateral List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: collateralList }}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollateralList;
