import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";

const schema = Yup.object().shape({
  collateralId: Yup.number().integer().min(0).required(),
});

const EndCollateral = ({ isOpen, setIsOpen, endCollateral }) => (
  <Formik
    validationSchema={schema}
    onSubmit={(evt) => {
      const { collateralId } = evt;

      endCollateral(collateralId);

      setIsOpen(false);

      toast("Collateral atualizado!", { type: "success" });
    }}
    initialValues={{
      collateralId: "",
    }}
  >
    {({ handleSubmit, handleChange, values, touched, errors }) => (
      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>End Collateral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="endCollateral" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Collateral ID</Form.Label>
              <Form.Control
                type="text"
                name="collateralId"
                value={values.collateralId}
                onChange={handleChange}
                isValid={touched.collateralId && !errors.collateralId}
                isInvalid={!!errors.collateralId}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="endCollateral" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default EndCollateral;
