import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import csvToJson from "csvtojson";
import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import ConnectionHeader from "./ConnectionHeader";
import AddCollateral from "./modals/AddCollateral";
import CollateralList from "./modals/CollateralList";
import NewTransfer from "./modals/NewTransfer";
import NewInvestment from "./modals/NewInvestment";
import NewPayment from "./modals/NewPayment";
import Resume from "./modals/Resume";
import EndCollateral from "./modals/EndCollateral";

dayjs.extend(customParseFormat);

function App() {
  const provider = useRef(null);
  const contract = useRef(null);
  const address = useRef(null);

  const [showResume, setShowResume] = useState(false);
  const [showNewTransfer, setShowNewTransfer] = useState(false);
  const [showNewInvestment, setShowNewInvestment] = useState(false);
  const [showNewPayment, setShowNewPayment] = useState(false);
  const [showAddCollateral, setShowAddCollateral] = useState(false);
  const [showEndCollateral, setShowEndCollateral] = useState(false);
  const [showCollateralList, setShowCollateralList] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const onInvestmentDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => toast("Operação abortada!", { type: "warning" });
      reader.onerror = () =>
        toast(
          "Houve um erro ao importar o arquivo CSV. Verifique o formato do mesmo!",
          { type: "error" }
        );
      reader.onload = async () => {
        try {
          const jsonArray = await csvToJson().fromString(reader.result);

          for (let i = 0; i < jsonArray.length; i++) {
            // transaction
            // interest_rate
            // investment_period
            // starting_date
            // payment_date
            // starting_date_unix
            // payment_date_unix
            // amount
            // due_amount
            // investor_address
            // USDC_investment_transaction
            const {
              interest_rate: interestRate,
              starting_date_unix: investmentStartingDateUnix,
              payment_date_unix: investmentPaymentDateUnix,
              amount: investmentAmount,
              due_amount: investmentDueAmount,
              investor_address: InvestorAddress,
              USDC_investment_transaction: USDCInvestmentTransaction,
            } = jsonArray[i];

            // interestRate,
            // investmentStartingDate,
            // investmentPaymentDate,
            // investmentAmount,
            // investmentDueAmount,
            // InvestorAddress
            // USDCInvestmentTransaction,
            contract.current?.newInvestment(
              interestRate.replace(/\D+/g, ""),
              investmentStartingDateUnix,
              investmentPaymentDateUnix,
              investmentAmount,
              investmentDueAmount,
              InvestorAddress,
              USDCInvestmentTransaction
            );
          }
        } catch (error) {
          console.log(error);
          toast(
            "Houve um erro ao importar o arquivo CSV. Verifique o formato do mesmo!",
            { type: "error" }
          );
        }
      };
      reader.readAsText(file);
    });
  };

  const {
    getInputProps: getInvestmentInputProps,
    open: investmentOpen,
    acceptedFiles: investmentAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: onInvestmentDrop,
  });

  const onPaymentDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => toast("Operação abortada!", { type: "warning" });
      reader.onerror = () =>
        toast(
          "Houve um erro ao importar o arquivo CSV. Verifique o formato do mesmo!",
          { type: "error" }
        );
      reader.onload = async () => {
        try {
          const jsonArray = await csvToJson().fromString(reader.result);

          for (let i = 0; i < jsonArray.length; i++) {
            //  id_investment
            //  USDC_investment_transaction
            const {
              id_investment: idInvestment,
              USDC_payment_transaction: USDCPaymentTransaction,
            } = jsonArray[i];

            // idInvestment,
            // USDCPaymentTransaction,
            contract.current?.newPayment(
              idInvestment,
              USDCPaymentTransaction,
            );
          }
        } catch (error) {
          console.log(error);
          toast(
            "Houve um erro ao importar o arquivo CSV. Verifique o formato do mesmo!",
            { type: "error" }
          );
        }
      };
      reader.readAsText(file);
    });
  };

  const {
    getInputProps: getPaymentInputProps,
    open: paymentOpen,
    acceptedFiles: paymentAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: onPaymentDrop,
  });

  return (
    <div>
      <ConnectionHeader
        provider={provider}
        contract={contract}
        address={address}
        setDisabled={setDisabled}
      />
      <p>
        <Button
          disabled={disabled}
          variant="info"
          onClick={() => setShowResume(true)}
        >
          Operation Resume
        </Button>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="info"
          onClick={() => setShowCollateralList(true)}
        >
          Collateral Assets Resume
        </Button>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="primary"
          onClick={() => setShowNewTransfer(true)}
        >
          Transfer
        </Button>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="primary"
          onClick={() => setShowNewInvestment(true)}
        >
          New Investment
        </Button>
      </p>
      <p>
        <input {...getInvestmentInputProps()} />
        <Button disabled={disabled} variant="primary" onClick={investmentOpen}>
          Upload Investments File
        </Button>

        <aside>
          <ul>
            {investmentAcceptedFiles.map((file) => (
              <li key={file.path}>{file.path}</li>
            ))}
          </ul>
        </aside>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="primary"
          onClick={() => setShowNewPayment(true)}
        >
          New Payment
        </Button>
      </p>
      <p>
        <input {...getPaymentInputProps()} />
        <Button disabled={disabled} variant="primary" onClick={paymentOpen}>
          Upload Payments File
        </Button>
        <aside>
          <ul>
            {paymentAcceptedFiles.map((file) => (
              <li key={file.path}>{file.path}</li>
            ))}
          </ul>
        </aside>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="primary"
          onClick={() => setShowAddCollateral(true)}
        >
          Add Collateral
        </Button>
      </p>
      <p>
        <Button
          disabled={disabled}
          variant="primary"
          onClick={() => setShowEndCollateral(true)}
        >
          End Collateral
        </Button>
      </p>
      <hr />

      <AddCollateral
        isOpen={showAddCollateral}
        setIsOpen={setShowAddCollateral}
        addCollateral={contract.current?.addCollateral}
      />

      <EndCollateral
        isOpen={showEndCollateral}
        setIsOpen={setShowEndCollateral}
        endCollateral={contract.current?.endCollateral}
      />

      <NewTransfer
        isOpen={showNewTransfer}
        setIsOpen={setShowNewTransfer}
        newTransfer={contract.current?.transfer}
      />

      <NewInvestment
        isOpen={showNewInvestment}
        setIsOpen={setShowNewInvestment}
        newInvestment={contract.current?.newInvestment}
      />

      <NewPayment
        isOpen={showNewPayment}
        setIsOpen={setShowNewPayment}
        newPayment={contract.current?.newPayment}
      />

      <Resume
        isOpen={showResume}
        setIsOpen={setShowResume}
        address={address}
        provider={provider.current}
        contract={contract.current}
      />

      <CollateralList
        isOpen={showCollateralList}
        setIsOpen={setShowCollateralList}
        getCollateralList={contract.current?.getCollateralList}
      />

      <ToastContainer />
    </div>
  );
}

export default App;
