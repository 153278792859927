import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";

const schema = Yup.object().shape({
  address: Yup.string().trim().required(),
  amount: Yup.number().integer().positive().required(),
});

const NewTransfer = ({ isOpen, setIsOpen, newTransfer }) => (
  <Formik
    validationSchema={schema}
    onSubmit={(evt) => {
      const { address, amount } = evt;

      newTransfer(address, amount);

      setIsOpen(false);

      toast("Transferência feita!", { type: "success" });
    }}
    initialValues={{
      address: "",
      amount: 0,
    }}
  >
    {({ handleSubmit, handleChange, values, touched, errors }) => (
      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="newTransfer" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                isValid={touched.address && !errors.address}
                isInvalid={!!errors.address}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                isValid={touched.amount && !errors.amount}
                isInvalid={!!errors.amount}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <Button type="submit" form="newTransfer" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default NewTransfer;
