import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Resume = ({ isOpen, setIsOpen, address, provider, contract }) => {
  const [activeInvesments, setActiveInvesments] = useState("");
  const [totalInvested, setTotalInvested] = useState("");
  const [totalActiveInvested, setTotalActiveInvested] = useState("");
  const [totalDueInvested, setTotalDueInvested] = useState("");
  const [totalPaid, setTotalPaid] = useState("");

  async function resume() {
    const _activeInvesments = await contract.getActiveInvestmentsCount();
    setActiveInvesments(parseInt(_activeInvesments._hex, 16));
    const _totalInvested = await contract.getTotalInvestedAmount();
    setTotalInvested(parseInt(_totalInvested._hex, 16)/10**18);
    const _totalActiveInvested = await contract.getTotalActiveInvestedAmount();
    setTotalActiveInvested(parseInt(_totalActiveInvested._hex, 16)/10**18);
    const _totalDueInvested = await contract.getTotalDueInvestedAmount();
    setTotalDueInvested(parseInt(_totalDueInvested._hex, 16)/10**18);
    const _totalPaid = await contract.getTotalPaidAmount();
    setTotalPaid(parseInt(_totalPaid._hex, 16)/10**18);
  }

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)} onShow={resume}>
      <Modal.Header closeButton>
        <Modal.Title>Operation Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Active Invesments: {activeInvesments}</p>
        <p>Total Invested: {totalInvested}</p>
        <p>Total Active Invested: {totalActiveInvested}</p>
        <p>Total Due Invested: {totalDueInvested}</p>
        <p>Total Paid: {totalPaid}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Resume;
